import {Box, Button,} from "@mui/material";
import './eventsSection.css';
import {useEffect, useState} from "react";
import EventsDetails from "../eventsDetails/EventsDetails";
import {addMonths, getDayAtStartOfDay, getGenitiveMonthName, subtractMonths} from "../../utils/dateUtils";
import {PRESSED_BUTTON} from "../customButtonGroup/CustomButtonGroup";
import {auxiliaryText, backgroundColor, radius} from "../../utils/cssConstants";


const EventsSection = ({filterCriteria, data, beforeAfterState}) => {
    const [indexOfVisibleArray, setIndexOfVisibleArray] = useState(3);
    const [filteredData, setFilteredData] = useState([]);
    const [visibleFilteredData, setVisibleFilteredData] = useState([]);

    /* Фильтрация Списка мероприятий при изменении filterCriteria и beforeAfterState */
    useEffect(() => {
        let localData = filterDataByCriteriaArray(filterCriteria, data);
        localData = filterByBeforeAfter(localData, beforeAfterState);

        setFilteredData(localData)
        setVisibleFilteredData(localData.slice(0, indexOfVisibleArray))

    }, [filterCriteria, beforeAfterState]);

    /* Фильтр по критериям */
    const filterDataByCriteriaArray = (selectedCriteriaArray, data) => {
        let localFilteredData = data.events;

        selectedCriteriaArray.forEach(criterion => {
            localFilteredData = localFilteredData.filter(event => {

                for (let filterCriterion of event.filterCriteria) {
                    if ((filterCriterion.filterId === criterion.filterId
                        && filterCriterion.optionId === criterion.optionId) || criterion.optionId === -1) {
                        return event;
                    }
                }
            });
        })

        return localFilteredData;
    }

    /* Фильтр для кнопок "предстоящие" и "прошедшие" */
    const filterByBeforeAfter = (data, beforeAfterState) => {

        if (beforeAfterState === PRESSED_BUTTON.NONE) {
            return data;
        }
        return data.filter(e => {
            let currentDate = getDayAtStartOfDay(new Date()).getTime();
            let eventDate = new Date(e.eventDate).getTime();
            return beforeAfterState === PRESSED_BUTTON.AFTER ?
                currentDate < eventDate && addMonths(new Date(), 6) >= eventDate
                : currentDate > eventDate && subtractMonths(new Date(), 3) <= eventDate;
        })
    }

    /* Логика для кнопки "Показать еще" */
    const handleClick = () => {

        let localData = filterDataByCriteriaArray(filterCriteria, data);
        localData = filterByBeforeAfter(localData, beforeAfterState);

        setFilteredData(localData)
        setVisibleFilteredData(localData.slice(0, indexOfVisibleArray + 3));
        setIndexOfVisibleArray(indexOfVisibleArray + 3);
    }


    return (
        <Box sx={{width: '100%'}}>
            {visibleFilteredData.map((d, index) => {
                const date = new Date(d.eventDate);
                const day = new Date(d.eventDate).getDate();
                const month = getGenitiveMonthName(date);
                return <EventsDetails
                    key={index}
                    data={{
                        description: d.description,
                        time: d.eventTime,
                        name: d.topic,
                        date: day,
                        month: month,
                        chip: d.eventType,
                        image: d.speakerImage,
                        speakerName: d.speakerName,
                        speakerPosition: d.speakerPosition,
                    }}


                />
            })}
            {filteredData.length > indexOfVisibleArray && <Button
                sx={{
                    height: '52px',
                    backgroundColor: backgroundColor,
                    color: auxiliaryText,
                    borderRadius: radius,
                    mb: 4,
                }}
                variant={'contained'}
                fullWidth
                onClick={handleClick}>Показать еще</Button>}
        </Box>
    )
}

export default EventsSection;