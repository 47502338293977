import {CardContent, Grid2, Typography} from "@mui/material";
import './calendarEventDetails.css';
import {darkFont, radius} from "../../utils/cssConstants";
import IconAndText, {ICON_TYPE} from "../IconAndText/IconAndText";

const CalendarEventDetails = ({name, time, location}) => {
    return (
        <CardContent className={'hoverable'}
                     sx={{
                         backgroundColor: 'white',
                         marginBottom: '16px',
                         paddingTop: '24px',
                         paddingLeft: '40px',
                         paddingRight: '40px',
                         borderRadius: radius,
                         minHeight: '9vh'
                     }}>
            <Typography variant="h5" fontSize={'24px'} color={darkFont}>
                {name}
            </Typography>
            <Grid2 container sx={{width: '25%', paddingTop: '16px'}}>
                <Grid2 size={6}>
                    <IconAndText
                        icon={ICON_TYPE.time}
                        text={time}/>
                </Grid2>
                <Grid2 size={6}>
                    <IconAndText
                        icon={ICON_TYPE.location}
                        text={location}/>
                </Grid2>
            </Grid2>
        </CardContent>
    )
}

export default CalendarEventDetails;