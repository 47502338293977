import './mainBody.css';
import {useState} from "react";
import CalendarSection from "../calendarSection/CalendarSection";
import EventsSection from "../eventsSection/EventsSection";
import FilterSection from "../filterSection/FilterSection";
import api from "../../services/api";
import {Box, Grid2, Typography} from "@mui/material";
import {lightestGrey} from "../../utils/cssConstants";
import {PRESSED_BUTTON} from "../customButtonGroup/CustomButtonGroup";
import jsonService from "../../services/jsonService";

const MainBody = () => {



    const data = api.getDataForMainPage();
    jsonService.getMonths();

    const [filterCriteria, setFilterCriteria] = useState([])
    const [pressedButton, setPressedButton] = useState(PRESSED_BUTTON.NONE);

    return (
        <Box className={'main-body'}>
            <CalendarSection data={data}/>
            <Typography variant={'h3'} color={lightestGrey}>
                Список мероприятий
            </Typography>
            <Grid2 container spacing={3} sx={{paddingTop: '40px'}}>
                <Grid2 size={2.8} sx={{position: 'sticky', top: '20px', maxHeight: '80vh'}}>
                    <FilterSection data={data}
                                   pressedButton={pressedButton}
                                   setPressedButton={setPressedButton}
                                   setFilterCriteria={setFilterCriteria}
                                   filterCriteria={filterCriteria}/>
                </Grid2>
                <Grid2 size={9.2}>
                    <EventsSection data={data} beforeAfterState={pressedButton} filterCriteria={filterCriteria}/>
                </Grid2>
            </Grid2>
        </Box>
    )

}

export default MainBody;