export function getGenitiveMonthName(date) {
    const formatter = new Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric'});
    const parts = formatter.formatToParts(date);
    return parts.find(part => part.type === 'month').value;
}

export function getDayAtStartOfDay(today) {
    return new Date(today.setHours(0, 0, 0, 0))
}

/* Добавляем месяцы к выбранной дате */
export const addMonths = (date, number) => {
    return new Date(date.setMonth(date.getMonth() + number));
}

/* Отнимаем месяцы у выбранной даты */
export const subtractMonths = (date, number) => {
    return new Date(date.setMonth(date.getMonth() - number));
}