import {Box, Grid2} from "@mui/material";

import './calendarSection.css';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import CustomCalendar from "../customCalendar/CustomCalendar";
import {useState} from "react";
import {getDayAtStartOfDay} from "../../utils/dateUtils";
import CalendarEvents from "../calendarEvent/CalendarEvents";

const CalendarSection = ({data}) => {

    dayjs.locale('ru');

    /* Если сегодня нет запланированных мероприятий, то показываем ближайшую дату с событиями  */
    const getClosestDateWithEvents = (data) => {
        let closestDay = null;
        const today =  getDayAtStartOfDay(new Date());
        data.events.forEach(e => {
            if (today <= getDayAtStartOfDay(new Date(e.eventDate)) && (closestDay == null ||
                getDayAtStartOfDay(new Date(e.eventDate)) < closestDay)) {
                closestDay = getDayAtStartOfDay(new Date(e.eventDate));
            }
        });
        return closestDay;
    }

    const [selectedDay, setSelectedDay] = useState(getClosestDateWithEvents(data));





    /*Фильтруем данные по выбранному дню в календаре*/
    const filterDataBySelectedDay = (selectedDay, data) => {
        if (selectedDay == null) {
            return [];
        }
        const formattedSelectedDay = `${selectedDay.getFullYear()}-${String(selectedDay.getMonth() + 1).padStart(2, '0')}-${String(selectedDay.getDate()).padStart(2, '0')}`
        return data.events.filter(event => event.eventDate == formattedSelectedDay);
    }

    const filteredData = filterDataBySelectedDay(selectedDay, data);

    return (
        <Box className="calendar-wrapper">
            <Grid2 container width={'100%'} spacing={'20px'}>
                <Grid2 size={4.35}>
                    <CustomCalendar data={data}
                                    selectedDay={selectedDay}
                                    setSelectedDay={setSelectedDay}/>
                </Grid2>
                <Grid2 size={7.65}>
                    <CalendarEvents
                        selectedDay={selectedDay}
                        filteredData={filteredData}/>
                </Grid2>
            </Grid2>
        </Box>

    )
}

export default CalendarSection;