import {Box, MenuItem, Select, Typography} from "@mui/material";
import './filterSection.css';
import {Fragment} from "react";
import CustomButtonGroup from "../customButtonGroup/CustomButtonGroup";
import {darkFont} from "../../utils/cssConstants";


const FilterSection = ({data, pressedButton, setPressedButton, setFilterCriteria, filterCriteria}) => {

    /* Обработка события select */
    const handleFilterCriterionChange = (optionId, filterId) => {
        const newObj = {filterId, optionId};
        let newArr = structuredClone(filterCriteria);
        newArr = newArr.filter(filterCriterion => filterCriterion.filterId !== filterId)
        newArr.push(newObj)
        setFilterCriteria(newArr)

    }

    return (
        <Fragment>
            <CustomButtonGroup setPressedButtonInGroup={setPressedButton}
                               pressedButtonInGroup={pressedButton}
                               buttonName1={'Предстоящие'} buttonName2={'Прошедшие'}/>
            {data.filters.map((filterItem, index) => {

                return <Box
                    key={index}
                    sx={{
                        height: 74,
                        borderRadius: '8px',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginBottom: '20px',
                        backgroundColor: 'white'
                    }}
                >
                    <Box sx={{
                        marginBottom: '16.5px',
                        marginTop: '16.5px',
                        marginLeft: '18px'
                    }}>
                        <Typography variant="subtitle1">
                            <Box className={'filter-name-text'}>
                                {filterItem.name}
                            </Box>
                        </Typography>
                        <Select
                            defaultValue={-1}
                            displayEmpty
                            variant="standard"
                            disableUnderline
                            onChange={(e) => {
                                handleFilterCriterionChange(e.target.value, filterItem.id)
                            }}
                            sx={{width: '93%', color: darkFont}}
                        >
                            <MenuItem selected value={-1} sx={{color: darkFont}}>
                                Не выбрано
                            </MenuItem>
                            {filterItem.options.map(option => (
                                <MenuItem key={option.id} value={option.id}
                                          sx={{
                                              whiteSpace: 'normal',
                                              maxWidth: '288px',
                                              width: '288px',
                                              color: darkFont
                                          }}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
            })}
        </Fragment>

    );
};

export default FilterSection;