import {addMonths} from "../utils/dateUtils";
import api from "./api";

const jsonService = {
    getMonths: () => {
        let monthStrings = _getAllMonthStringArray();
        monthStrings.map(async monthString => {
            let result = await api.getMonthByUrl(monthString);
            debugger
            return monthString;
        })

    }
}

const _getMonthNameStringArray = (year, arr) => {
    let startDate = new Date('2024-01-01');
    for (let i = 0; i < 12; i++) {
        let monthNumber = (startDate.getMonth() + 1).toString().padStart(2, '0');
        let monthStr = `${monthNumber}-${year}`;
        arr.push(monthStr);
        addMonths(startDate, 1);
    }
}

const _getAllMonthStringArray = () => {
    let arr = [];
    _getMonthNameStringArray('2024', arr);
    _getMonthNameStringArray('2025', arr);
    return arr;
}

export default jsonService;