import {Box, Grid2, Typography} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import {auxiliaryText} from "../../utils/cssConstants";

export const ICON_TYPE = {
    time: <AccessTimeIcon sx={{color: auxiliaryText, position: 'absolute'}}/>,
    location: <FmdGoodOutlinedIcon sx={{color: auxiliaryText, position: 'absolute'}}/>
}

const IconAndText = ({icon, text}) => {

    return <Grid2 container>
        <Grid2 size={4} sx={{position: 'relative', bottom: 0}}>
            {icon}
        </Grid2>
        <Grid2 size={6}>
            <Typography component={'span'}>
                <Box sx={{
                    fontSize: '18px',
                    color: auxiliaryText,
                    whiteSpace: 'nowrap',
                    overflow: 'visible'
                }}>{text}</Box>
            </Typography>
        </Grid2>
    </Grid2>
}

export default IconAndText;