import {Button, Grid2, Typography} from "@mui/material";
import './mainFooter.css';
import img from "../../assets/images/logoblue.svg";
import {footerColor} from "../../utils/cssConstants";
import {addMonths} from "../../utils/dateUtils";

const MainFooter = () => {


    const getMonthNameStringArray = (year, arr) => {
        let startDate = new Date('2024-01-01');
        for (let i = 0; i < 12; i++) {
            let monthNumber = (startDate.getMonth() + 1).toString().padStart(2, '0');
            let monthStr = `${monthNumber}-${year}`;
            arr.push(monthStr);
            addMonths(startDate, 1);
        }

    }

    const getAllArray = () => {
        let arr = []
        getMonthNameStringArray('2024', arr);
        getMonthNameStringArray('2025', arr);
    }


    return (
        <Grid2 container
               sx={{
                   height: '83px'
               }}
        >
            <Grid2 size={6} sx={{display: 'flex', justifyContent: 'start', paddingLeft: '200px', mt: "24px"}}>
                <img
                    alt=''
                    className={'img-class__footer'}
                    width={'98px'}
                    height={'35px'}
                    src={img}/>
            </Grid2>
            <Grid2 size={6} sx={{display: 'flex', justifyContent: 'end', paddingRight: '200px', mt: "31px"}}>
                <Typography
                    className={'footer-copyright'} color={footerColor}> © ВТБ, 2024
                </Typography>
                <Button onClick={() => {
                        getAllArray()
                    }
                }>test</Button>
            </Grid2>
        </Grid2>

    )

}

export default MainFooter;