import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {DateCalendar} from "@mui/x-date-pickers/DateCalendar";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {PickersDay} from "@mui/x-date-pickers";
import './customCalendar.css';
import {getDayAtStartOfDay} from "../../utils/dateUtils";
import {darkFont, filterButtonColor, lightestGrey, radius} from "../../utils/cssConstants";
import {useState} from "react";

const CustomCalendar = ({data, selectedDay, setSelectedDay}) => {
    dayjs.locale('ru');

    const [selectedDate] = useState(dayjs(new Date()));

    let heightValue = 'calc(13% - 10px)';
    let widthValue = 'calc(13% - 10px)';

    const sx = {

        '& .MuiBadge-badge': {
            // Adjustment for recordMade badge
            fontSize: '0.7em',
            paddingTop: '4px'
        },

        '& .MuiDayCalendar-header': {
            justifyContent: 'center',
            width: '100%',
            overflow: 'hidden',
            margin: '2px, 0'

        },
        '& .MuiPickersDay-root': {
            color: darkFont
        },
        '& .MuiDayCalendar-weekContainer': {
            // Adjusts spacing between days (ie 1, 2, 3.. 27, 28)
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            margin: 0
        },
        '& .MuiPickersDay-dayWithMargin': {
            // Grows width/height of day buttons
            width: widthValue,
            height: heightValue,
            aspectRatio: '1',
            fontSize: '1.0em',
        },
        '& .MuiBadge-root': {
            // Parent of button management
            aspectRatio: 1,
            width: '10%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
        },
        '& .MuiDayCalendar-weekDayLabel': {
            // Manages size of weekday labels
            aspectRatio: 1,
            width: widthValue,
            height: heightValue, // deals with margin
            fontSize: '1.0em',
            color: lightestGrey
        },
        '& .MuiPickersCalendarHeader-label': {
            // Manages month/year size
            fontSize: '1.3em',
            color: darkFont,
            textTransform: 'capitalize'
        },
        '& .MuiDayCalendar-monthContainer': {
            // Not sure if needed, currently works tho
            width: '100%'
        },
        '& .MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer': {
            // Handles size of week row parent, 1.6 aspect is good for now
            aspectRatio: '1.6',
            overflow: 'hidden'
        },
        '& .MuiDayCalendar-slideTransition': {
            // Handles size of week row parent, 1.6 aspect is good for now
            aspectRatio: 1.6,
            width: '100%',
            overflow: 'hidden'
        },
        '& .MuiDayCalendar-loadingContainer': {
            width: '100%',
            aspectRatio: 1.6,
        },
        '& .MuiDayCalendarSkeleton-root': {
            width: '100%'
        },
        '& .MuiDayCalendarSkeleton-week': {
            width: '100%'
        },
        '& .MuiDayCalendarSkeleton-daySkeleton': {
            aspectRatio: '1 !important',
            height: 'auto !important'
        },
        '& .MuiPickersSlideTransition-root': {
            height: '480px',
        },
        '& .MuiPickersArrowSwitcher-leftArrowIcon, & .MuiPickersArrowSwitcher-rightArrowIcon': {
            borderRadius: '50%',
            border: 'solid',
            borderWidth: '1px',
            padding: '3px',
            borderColor: filterButtonColor,
            color: filterButtonColor,
        },

        width: '100%',
        maxHeight: '100%',
        height: '440px',
        backgroundColor: 'white',
        borderRadius: radius
    }


    const highlightedDays = data.events.map(d => d.eventDate);

    /* Логика для выбора стиля дня в календаре в зависимости от типа дня */
    const renderCustomDay = (props) => {
        const {highlightedDays = [], day, outsideCurrentMonth, ...other} = props;

        const containsEvents =
            highlightedDays.includes(day.format("YYYY-MM-DD"));

        let pickersDay = getPickersDay(null, other, outsideCurrentMonth, day);

        if (selectedDay?.getTime() === new Date(day).getTime()) {

            pickersDay = getPickersDay('selected-day', other, outsideCurrentMonth, day );
        } else if (outsideCurrentMonth) {

            pickersDay = getPickersDay('outside-of-current-month', other, outsideCurrentMonth, day);
        } else if (containsEvents) {

            pickersDay = getPickersDay('has-events', other, outsideCurrentMonth, day);
        } else {
            pickersDay = getPickersDay('unclickable-day', other, outsideCurrentMonth, day);
        }


        return pickersDay;
    };

    const getPickersDay = (className, other, outsideCurrentMonth, day) => {
        return (
            <PickersDay
                className={className}
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
            />
        );
    }

    /* Если день в календаре не содержит мероприятий, то делаем его некликабельным */
    const setCorrectSelectedDay = (e) => {
        if (highlightedDays.includes(e.format("YYYY-MM-DD"))) {
            setSelectedDay(getDayAtStartOfDay(new Date(e)))
        }
    }

    return <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="ru"
        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DateCalendar
            showDaysOutsideCurrentMonth={true}
            onChange={(e) => {
                setCorrectSelectedDay(e);
            }}
            slots={{
                day: renderCustomDay,
            }}
            slotProps={{day: {highlightedDays}}}
            sx={sx}
            dayOfWeekFormatter={(weekday) => `${weekday.format('dd')}`}
        />
    </LocalizationProvider>
}

export default CustomCalendar;