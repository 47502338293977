export const getData = async (url) => {
    return fetch(url)
        .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let result = '';

            return new Promise((resolve, reject) => {
                function read() {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            resolve(result);  // Resolve the final result
                            return;
                        }
                        // Decode the chunk and append to the result
                        result += decoder.decode(value, { stream: true });
                        read();  // Continue reading the next chunk
                    }).catch(error => reject(error));
                }

                read();
            });
        })
        .catch(error => {
            console.error('Error fetching the data:', error);
        });
};