import {Box, Button, CardContent, Chip, Grid2, Typography} from "@mui/material";
import {Fragment} from "react";
import './eventsDetails.css';
import {
    auxiliaryText,
    backgroundColor,
    darkFont,
    filterButtonColor,
    gradientColor,
    lightGrey,
    radius
} from "../../utils/cssConstants";
import IconAndText, {ICON_TYPE} from "../IconAndText/IconAndText";


const eventsDetails = ({data}) => {

    const renderDateSection = () => {
       return <Fragment>
           <Typography variant={'h1'}>
               <Box className={'date-primary'} sx={{
                   fontSize: '80px',
               }}>
                   {data.date}
               </Box>
           </Typography>
           <Typography variant={'h5'}>
               <Box className={'month-name-primary'} sx={{
                   color: darkFont,
                   fontSize: '24px'
               }}>
                   {data.month}
               </Box>
           </Typography>
           <Box sx={{marginLeft: '25%', marginRight: '25%', paddingTop: '32px'}}>
               <IconAndText
                   icon={ICON_TYPE.time}
                   text={data.time}/>
           </Box>
       </Fragment>
    }

    const renderButtonsBlock = () => {
        return <Fragment>
            <Button variant={'contained'} sx={{
                height: '52px',
                width: '148px',
                fontSize: '18px',
                marginRight: '16px',
                backgroundImage: gradientColor,
                borderRadius: radius,
                textTransform: 'none',
            }}>
                Подробнее
            </Button>
            <Button variant={'outlined'} sx={{
                height: '52px',
                width: '148px',
                fontSize: '18px',
                borderColor: lightGrey,
                color: filterButtonColor,
                borderRadius: radius,
                textTransform: 'none',
            }}>
                Смотреть
            </Button>
        </Fragment>
    }
        const renderCardText = () => {
            return <Fragment>
                <Typography variant={'h4'}>
                    <Box className={'event-card-title-text'} sx={{
                        color: darkFont
                    }}>
                        {data.name}
                    </Box>

                </Typography>
                <Typography variant={'h6'} sx={{paddingBottom: '20px'}}>
                    <Box className={'event-card-description-text'} sx={{
                        color: auxiliaryText
                    }}>
                        {data.description}
                    </Box>
                </Typography>
            </Fragment>
        }

    const renderImageAndText = () => {
        return <Fragment>
            <img alt='' src={data.image} width={'64px'} height={'64px'}/>
            <Typography variant={'h5'}><Box
                sx={{fontSize: '18px', fontWeight: 500, color: darkFont}}>{data.speakerName}</Box></Typography>
            <Typography variant={'h6'}>
                <Box sx={{fontSize: '16px', fontWeight: 300, color: darkFont}}>
                    {data.speakerPosition}
                </Box>
            </Typography>
        </Fragment>
    }

    return (
        <CardContent className={'hoverable'}
                     sx={{
                         padding: 0,
                         marginBottom: '30px',
                         position: 'relative',
                         backgroundColor: 'white',
                         borderRadius: radius,
                         '&::before, &::after': {
                             content: '""',
                             position: 'absolute',
                             top: 0,
                             bottom: 0,
                             width: '1px',
                             backgroundColor: backgroundColor,
                         },
                         '&::before': {
                             left: '19%',
                         },
                         '&::after': {
                             left: '76%',
                         },
                         '&:hover::before, &:hover::after': {
                             top: 1,
                             bottom: 1,
                         }
                     }}>
            <Chip label={data.chip} className={'custom-chip'}></Chip>
            <Grid2 container spacing={2} sx={{minHeight: '26vh'}}>
                <Grid2 size={2.5} sx={{
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingLeft: '16px'
                }}>
                    {renderDateSection()}
                </Grid2>
                <Grid2 size={6.5} sx={{paddingTop: '32px', paddingBottom: '32px'}}>
                    {renderCardText()}
                    {renderButtonsBlock()}
                </Grid2>
                <Grid2 size={3} sx={{
                    paddingTop: '32px',
                    paddingBottom: '32px',
                    paddingRight: '32px',
                    paddingLeft: '20px',
                }}>
                    {renderImageAndText()}
                </Grid2>
            </Grid2>
        </CardContent>
    )
}

export default eventsDetails;