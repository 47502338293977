import data from '../data/data.json';
import {getData} from "../utils/httpUtils";

const api = {
    getDataForMainPage: () => {
        return data;
    },
    getEvent: async (hash) => {
        try {
            return await getData(`http://localhost:3001/events/${hash}/data.json`);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
    getMonthByUrl: async (month) => {
        try {
            return await getData(`https://afisha.vtb.d2.3dev.tech/events/month/${month}.json`);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
}

export default api;
