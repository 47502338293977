import {Box, Typography} from "@mui/material";
import './mainHeader.css';
import img from '../../assets/images/logowhite.svg'


const MainHeader = () => {
    return (
        <Box className={'main-header'}>
            <img
                alt=''
                className={'img-class'}
                width={'98px'}
                height={'35px'}
                src={img}/>
            <Typography
                className={'main-header__text-header'}>Афиша мероприятий</Typography>
            <Typography
                className={"main-header__text-description"}>
                Уроки проходят в рамках проекта ЦБ РФ «Онлайн уроки финансовой грамотности»</Typography>

        </Box>
    )
}

export default MainHeader;