import React, {useState} from 'react';
import {Box, Button, ButtonGroup} from '@mui/material';
import {auxiliaryText, filterButtonColor, radius} from "../../utils/cssConstants";

export const PRESSED_BUTTON = {
    BEFORE: 'before',
    AFTER: 'after',
    NONE: null
}

const buttonStyles = {
    pressed: {
        backgroundColor: 'white',
        color: filterButtonColor,
        borderColor: filterButtonColor,
        border: 'solid',
        borderRadius: radius
    },
    released: {
        backgroundColor: 'inherit',
        color: auxiliaryText,
        borderColor: 'inherit',
        border: 'none',
        borderRadius: radius
    }
};
const CustomButtonGroup = ({setPressedButtonInGroup, pressedButtonInGroup, buttonName1, buttonName2}) => {
    const [pressedButton, setPressedButton] = useState(0);

    /*Выбираем мероприятия в зависимости от кнопки (прошедшие или предстоящие)*/
    const handleButtonClick = (number, beforeAfter) => {
        setPressedButtonInGroup(pressedButtonInGroup === beforeAfter ? PRESSED_BUTTON.NONE : beforeAfter)
        setPressedButton(pressedButton === number ? 0 : number);
    };


    return (
        <Box sx={{paddingBottom: '20px'}}>
            <ButtonGroup variant="contained" aria-label="color button group" fullWidth
                         sx={{height: '60px', borderRadius: '8px'}}>
                <Button
                    style={pressedButton === 1 ? buttonStyles.pressed : buttonStyles.released}
                    fullWidth
                    onClick={() => {
                        handleButtonClick(1, PRESSED_BUTTON.AFTER)
                    }}
                >
                    {buttonName1}
                </Button>
                <Button fullWidth
                        style={pressedButton === 2 ? buttonStyles.pressed : buttonStyles.released}
                        onClick={() => {
                            handleButtonClick(2, PRESSED_BUTTON.BEFORE)
                        }}
                >
                    {buttonName2}
                </Button>
            </ButtonGroup>
        </Box>
    );
};

export default CustomButtonGroup;
