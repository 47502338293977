import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import EventsPage from "./components/pages/EventsPage";
import EventPage from "./components/pages/EventPage";

function App() {
    const theme = createTheme({
        typography: {
            fontFamily: '"VTBGroupUI", sans-serif',
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    {/*<Route path="/" element={<Navigate to="/afisha/events" />} />*/}
                    <Route path="/" element={<EventsPage />} />
                    <Route path="/afisha/event/:id" element={<EventPage />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
