const rootStyles = getComputedStyle(document.documentElement);
export const auxiliaryText = rootStyles.getPropertyValue('--axilllaryText').trim();
export const filterButtonColor = rootStyles.getPropertyValue('--filterButtonColor').trim();
export const darkFont = rootStyles.getPropertyValue('--darkFont').trim();
export const radius = rootStyles.getPropertyValue('--radius').trim();
export const gradientColor = rootStyles.getPropertyValue('--gradientColor').trim();
export const lightGrey = rootStyles.getPropertyValue('--lightGrey').trim();
export const lightestGrey = rootStyles.getPropertyValue('--lightestGrey').trim();
export const backgroundColor = rootStyles.getPropertyValue('--backgroundColor').trim();
export const footerColor = rootStyles.getPropertyValue('--footerColor').trim();
export const whiteColor = rootStyles.getPropertyValue('--whiteColor').trim();